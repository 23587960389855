.table-div{
    border-radius: 10px 10px 0 0;
    box-shadow: 0 0 0 1px #ddd;
    overflow-y: auto;
    overflow-x: auto;
}

.none-overflow{
    overflow: hidden;
}

.common-table {
    table-layout: fixed;
    text-align: center !important;
    z-index: 0 !important;
    width: 100%;
    border-collapse: collapse;
    border-style: hidden;
}

.common-table tr, .common-table td, .common-table th{
    border: 1px solid #ddd;
}

.common-table td{
    padding: 2px;
}

.common-table thead{
    position: sticky;
    background-color: #ff9d2b;
    top: 0;
    color: white;
    height: 30px !important;
}

.common-table tr.-even {
    background-color: white;
}

.common-table tbody{
    background-color: #ffffff !important;
}

tbody tr:nth-child(2n) {
    background-color: #faf5f0;
}

tbody tr:nth-child(2n+1) {
    background-color: #fff;
}

tbody tr:nth-child(n):hover {
    background-color: #f5ece4 !important;
}

.common-table .control-button {
    background-color: #808080;
    color: white !important;
    margin: 0 2px 0;
    width: 28px;
    height: 28px;
    font-size: small;
    border-color: #808080;
}

.common-table .control-button-disabled {
    background-color: #444444;
    color: #808080 !important;
    margin: 0 2px 0;
    width: 28px;
    height: 28px;
    font-size: small;
    border-color: #808080;
}

.common-table .control-button-disabled:hover, .common-table .control-button-disabled:focus {
    background-color: #333333;
    color: #808080;
    outline: none;
    margin: 0 2px 0;
    width: 28px;
    height: 28px;
    font-size: small;
    border-color: #808080;
}

.pagination {
    padding: 0.8%;
    background-color: #eee;
    text-align: center;
    justify-content: center;
    box-shadow: 0 0 5px 0 #ddd
}

.pagination .page-btn{
    margin-left: 0.5%;
}
.pagination .page-span{
    margin-top: 0.3%;
    margin-left: 1%;
    margin-right: 1%;
}

.pagination .page-input{
    width: 50% !important;
    margin-right: 3px;
}

.no-data-text, .table-loading{
    padding: 5%;
    text-align: center !important;
}

.common-table .sub_user_plus-button {
    background-color: #ff9d2b;
    color: white !important;
    margin: 5px auto;
    width: 28px;
    height: 28px;
    font-size: small;
    border-color: #808080;
}

.common-table .sub_user_plus-button:hover, .common-table .sub_user_plus-button:focus {
    background-color: #e67a00;
}

.common-table .sub_user_row {
    margin: 0 0 5px;
}

.common-table .sub_user_row_head {
    margin: auto;
    height: auto;
}

.common-table .voice_3month_row_head {
    margin: auto;
    height: auto;
}

.common-table .sub_user_minus-button {
    background-color: #808080;
    color: white !important;
    margin: 5px auto;
    width: 28px;
    height: 28px;
    font-size: small;
    border-color: #808080;
}

.common-table .sub_user_minus-button:hover, .common-table .sub_user_minus-button:focus {
    background-color: #c5c5c5;
    color: white !important;
    margin: 5px auto;
    width: 28px;
    height: 28px;
    font-size: small;
    border-color: #808080;
}

.common-table .sub_user_minus-button-space {
    margin: auto;
    width: 28px;
    height: 28px;
}

.common-table .sub_user_password_change {
    display: flex;
}
