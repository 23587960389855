.user_password_change-button {
    background-color: #ff9d2b;
    color: white !important;
    margin: 5px 5px 5px 5px;
    width: 28px;
    height: 28px;
    font-size: small;
    border-color: #808080;
}

.user_password_change-button:hover, .user_password_change-button:focus  {
    background-color: #e67a00;
}

.user_password_cancel-button {
    background-color: #808080;
    color: white !important;
    margin: 5px 5px 5px 5px;
    width: 28px;
    height: 28px;
    font-size: small;
    border-color: #808080;
}

.user_password_cancel-button:hover, .user_password_cancel-button:focus {
    background-color: #c5c5c5;
}

.user_password_change {
    display: flex;
}

.options-check-row {
    display: flex;
    margin-bottom: 5px;
}

.options-check-description {
    margin: 3px 5px 5px;
}
